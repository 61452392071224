import approvalFlow from "@/api/setting/approval-flow";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    employees: [],
    logicalPositions: [],
    jobTitles: [],
    approvalTypes: [],
    approverTypes: [],
    approvalFlowParameters: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE(state, data) {
        state.employees = data;
    },
    SET_LOGICALPOSITION(state, data) {
        state.logicalPositions = data;
    },
    SET_APPROVALTYPE(state, data) {
        state.approvalTypes = data;
    },
    SET_APPROVERTYPE(state, data) {
        state.approverTypes = data;
    },
    SET_APPROVALFLOWPARAMETER(state, data) {
        state.approvalFlowParameters = data;
    },
    CLEAR_LOGICALPOSITION(state) {
        state.approvalFlowParameters = null;
    },
    SET_JOBTITLE(state, data) {
        state.jobTitles = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return approvalFlow.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return approvalFlow.store(data);
    },

    update(context, { id, data }) {
        return approvalFlow.update(id, data);
    },

    changeIsEnabled(context, { id, data }) {
        return approvalFlow.changeIsEnabled(id, data);
    },

    async getLogicalPosition(context, attributes = {}) {
        let response = await approvalFlow.logicalPosition({
            params: attributes
        });
        context.commit("SET_LOGICALPOSITION", response.data);
    },
    async getEmployee(context, attributes = {}) {
        let response = await approvalFlow.employee({
            params: attributes
        });
        context.commit("SET_EMPLOYEE", response.data);
    },
    async getApprovalType(context, attributes = {}) {
        let response = await approvalFlow.approvalType({
            params: attributes
        });
        context.commit("SET_APPROVALTYPE", response.data);
    },
    async getApproverType(context, attributes = {}) {
        let response = await approvalFlow.approverType({
            params: attributes
        });
        context.commit("SET_APPROVERTYPE", response.data);
    },
    async getApprovalFlowParameter(context, { id }) {
        let response = await approvalFlow.approvalFlowParameter(id);
        context.commit("SET_APPROVALFLOWPARAMETER", response.data);
    },
    async clearApprovalFlowParameter(context) {
        context.commit("CLEAR_LOGICALPOSITION");
    },
    async getJobTitle(context, attributes = {}) {
        let response = await approvalFlow.jobTitle({
            params: attributes
        });
        context.commit("SET_JOBTITLE", response.data);
    },
};
